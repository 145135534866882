import { Button, Col, DatePicker, Form, Input, Modal, ModalFuncProps, Radio, Row, Typography, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { email, required } from '../../../../../utils/inputRules';
import { useContextReports } from '../../../../../context/reports';

const GetQuoteModal = ({ open, onOk, onCancel }: ModalFuncProps) => {
  const address = useWatch(['loans', 0, 'address']);
  const { handleLoansUpdate, loans } = useContextReports();
  const [form] = useForm();
  const [reportId, setReportID] = useState<string | undefined>();

  const handleSave = () => {
    form.validateFields().then((values) => {
      const { name, dateOfBirth, ...data } = values;
      const [firstName, lastName] = name.split(' ');

      onOk?.(
        {
          ...data,
          firstName,
          lastName,
          dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD HH:mm:ss'),
          reportId: reportId || loans?.[0]?.reportId,
        },
        onCancel
      );
    });
  };

  useEffect(() => {
    if (!open) return;

    form.resetFields();
  }, [open]);

  const handleSelect = (value: string) => {
    const selectedLoan = loans && loans.find((loan) => loan.id === value);

    form.setFieldsValue({ propertyAddress: selectedLoan && selectedLoan.address });
    setReportID(selectedLoan?.reportId);
    handleLoansUpdate?.();
  };

  return (
    <Modal closable={false} footer={null} open={open} onCancel={onCancel} style={{ maxWidth: 440 }}>
      <div className="flex-col gap-20">
        <Typography.Title style={{ color: '#464646' }} level={2}>
          Get a Quote
        </Typography.Title>
        <Typography.Text className="fs-1">
          Provide your information and receive a personalized homeowner's insurance quote within 24 hours.
        </Typography.Text>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            propertyAddress: address && address.replace(/;/g, ','),
            isInvestment: false,
            isRefinance: false,
          }}
        >
          <Form.Item name="propertyAddress" label="Property address" className="label-gray" rules={required('address')}>
            <Select
              placeholder="Enter Property Address"
              value={address}
              options={loans?.map((loan) => ({
                value: loan.id,
                label: loan.address,
              }))}
              onSelect={handleSelect}
            />
          </Form.Item>
          <Form.Item name="isInvestment">
            <Radio.Group>
              <Row>
                <Col md={12} span={24}>
                  <Radio value={false}>Primary residence</Radio>
                </Col>
                <Col md={12} span={24}>
                  <Radio value>Investment</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="isRefinance">
            <Radio.Group>
              <Row>
                <Col md={12} span={24}>
                  <Radio value>Refinance</Radio>
                </Col>
                <Col md={12} span={24}>
                  <Radio value={false}>Purchase</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dateOfBirth" label="Date of birth" className="label-gray" rules={required('date of birth')}>
            <DatePicker size="large" format="MM/DD/YYYY" className="w-100" allowClear />
          </Form.Item>
          <Form.Item name="name" label="Borrower name" className="label-gray" rules={required('borrower name')}>
            <Input size="large" placeholder="Enter Name" />
          </Form.Item>
          <Form.Item name="email" label="Email" className="label-gray" rules={[...required('email'), ...email]}>
            <Input size="large" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Phone" className="label-gray" rules={required('phone number')}>
            <Input size="large" placeholder="Enter Phone" />
          </Form.Item>
        </Form>
        <div className="flex-row flex-justify-end gap-8">
          <Button type="default" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" size="large" onClick={handleSave}>
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GetQuoteModal;
