import React, { useEffect, useState } from 'react';
import { Col, InputNumber, Row, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useReportSavingMonthly } from '../../../../../hooks/reports';
import { IBarChartData } from '../../../../Common/Charts/types';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import { useContextReports } from '../../../../../context/reports';
import MovingTo from '../../../../Common/MovingTo';
import BarChart from '../../../../Common/Charts/BarChart';
import { commafy } from '../../../../../utils/text';
import useDebounce from '../../../../../hooks/debounce';

interface ISavingForIncreasing {
  provided?: DraggableProvided;
}

const SavingForIncreasing = ({ provided }: ISavingForIncreasing) => {
  const name = 'savingForIncreasing';
  const [loanKey, setLoanKey] = useState<number>(0);
  const form = useFormInstance();
  const { loans } = useContextReports();
  const movingTo = useWatch(['movingTo', name], form);
  const calculateSaving = useReportSavingMonthly();
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState<IBarChartData | undefined>(undefined);
  const [montlyIncrease, setMontlyIncrease] = useState<string | null>(null);
  const debouncedMontlyIncrease = useDebounce(montlyIncrease, 500);

  useEffect(() => {
    if (!isSectionActive || !movingTo || !loans) return

    const loanIndex = loans.findIndex(item => item.id === movingTo);

    if (loanIndex >= 0) {
      setLoanKey(loanIndex);
      setMontlyIncrease(loans?.[loanIndex]?.montlySavingIncrease);
    }

  }, [isSectionActive, movingTo, loans]);

  const handleCalculateSavings = (montlyIncreaseValue: string | undefined) => {
    if (!movingTo || !isSectionActive || !loans || !loans[loanKey] || !montlyIncreaseValue) return;

    calculateSaving
      .fetch({
        id: movingTo,
        montlySavingIncrease: montlyIncreaseValue,
      })
      .then((res) => {
        if (res?.data) {
          const currentYear = new Date().getFullYear();
          const result = res.data.savingArray.map((value, i) => ({
            x: currentYear + i,
            y: parseFloat(value),
          }));

          const chartData: IBarChartData = {
            labels: result.map(item => String(item.x)),
            datasets: [
              {
                label: 'Savings Over Time',
                data: result.map(item => item.y),
                maxBarThickness: 15,
                inflateAmount: 1,
                backgroundColor: '#1e0c68',
                borderRadius: {
                  topLeft: 4,
                  topRight: 4,
                },
              },
            ],
          };

          setData(chartData);
        }
      });
  };

  useEffect(() => {
    form.setFieldValue(['loans', loanKey, 'montlySavingIncrease'], montlyIncrease);
    handleCalculateSavings(typeof debouncedMontlyIncrease === 'string' ? debouncedMontlyIncrease : undefined);
  }, [debouncedMontlyIncrease, loans?.[loanKey]?.montlySavingIncrease, movingTo,
    loans?.[loanKey].purchasePrice, loans?.[loanKey].downPayment, loans?.[loanKey].loanAmount]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReportSection
    id={name}
    name={name}
    title='Impact of Increasing Monthly Payment on Mortgage Savings'
    provided={provided}
  >
    <Space direction='vertical' size={20} className="impact-container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <Row style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <Col style={{ paddingTop: '10px', flex: '1' }}>
          <Space size={60} style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}>
            <TextWithLabel
              label='Total Interest Savings Over The Life Of The Loan'
              value={`$${commafy(calculateSaving.data?.data?.totalSaving || 0) === 'NaN'
                ? 0 : commafy(calculateSaving.data?.data?.totalSaving || 0)}`}
            />
            {windowWidth >= 867 && (
              <TextWithLabel
                label='Loan Payoff Acceleration'
                value={`${calculateSaving.data?.data.yearsSaving || 0} years,
                ${calculateSaving.data?.data.monthsSaving || 0} months`}
              />
            )}
            {windowWidth >= 867 && (
              <div style={{ display: 'flex', flexDirection: 'column', width: '150px', flexShrink: 0 }}>
                <Typography.Text className="color-gray fs-0875 ws-nowrap">
                  Additional Monthly Payment
                </Typography.Text>
                <InputNumber
                  onFocus={() => {
                    if (montlyIncrease === '0') {
                      setMontlyIncrease('');
                    }
                  }}
                  size='large'
                  formatter={(value) => value ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                  value={montlyIncrease || undefined}
                  onChange={value => setMontlyIncrease(value ? String(value) : null)}
                  parser={value => value?.replace(/\$\s?|(,*)/g, '') || ''}
                  placeholder="$0"
                  min='0'
                  style={{ width: '100%' }}
                  controls={false}
                />
              </div>
            )}
            {windowWidth >= 867 && (
              <MovingTo name={name} label='Select Loan Option' />
            )}
          </Space>
        </Col>
        {windowWidth < 867 && (
          <Col xl={12} span={24}>
            <TextWithLabel
              label='Loan Payoff Acceleration'
              value={`${calculateSaving.data?.data.yearsSaving || 0} years,
              ${calculateSaving.data?.data.monthsSaving || 0} months`}
            />
          </Col>
        )}
        {windowWidth < 867 && (
          <Col xl={12} span={24}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography.Text className="color-gray fs-0875 ws-nowrap">
                Additional Monthly Payment
              </Typography.Text>
              <InputNumber
                size='large'
                onFocus={() => {
                  if (montlyIncrease === '0') {
                    setMontlyIncrease('');
                  }
                }}
                formatter={(value) => value ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                value={montlyIncrease || undefined}
                onChange={value => setMontlyIncrease(value ? String(value) : null)}
                parser={value => value?.replace(/\$\s?|(,*)/g, '') || ''}
                placeholder="$0"
                min='0'
                style={{ width: '100%' }}
                controls={false}
              />
            </div>
          </Col>
        )}
        {windowWidth < 867 && (
          <Col xl={12} span={24}>
            <MovingTo name={name} label='Select Loan Option' />
          </Col>
        )}
      </Row>
      <BarChart mode='nearest' hideLegend data={data} loading={calculateSaving.loading} />
    </Space>
  </ReportSection>
  );
};

SavingForIncreasing.defaultProps = {
  provided: undefined,
};

export default SavingForIncreasing;
