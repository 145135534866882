import { Col, Row, Space, Radio, RadioChangeEvent, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import {
  IReportLoan,
  IReportRentalAnalysisKey,
  IReportRentalAnalysisParams,
  useReportRentalAnalysis,
  useReportRentalAnalysisInitial,
} from '../../../../../hooks/reports';
import LineChart from '../../../../Common/Charts/LineChart';
import { ILineChartData } from '../../../../Common/Charts/types';
import MovingTo from '../../../../Common/MovingTo';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import Customize from './Customize';
import { commafy } from '../../../../../utils/text';
import { defaultDatasets } from '../../constants';

interface IReportRentalAnalysis {
  bedrooms0Average?: number;
  bedrooms1Average?: number;
  bedrooms2Average?: number;
  bedrooms3Average?: number;
  bedrooms4Average?: number;
  bedrooms5Average?: number;
}

interface IRentalAnalysis {
  provided?: DraggableProvided;
}

const RentalAnalysis = ({ provided }: IRentalAnalysis) => {
  const name = 'rentalAnalysis';
  const [datasets, setDatasets] = useState(defaultDatasets);
  const [range, setRange] = useState<number>(36);
  const [data, setData] = useState<ILineChartData>();
  const rentalAnalysis = useReportRentalAnalysis();
  const getInitial = useReportRentalAnalysisInitial();
  const [loanKey, setLoanKey] = useState<number>(0);
  const { loans: reportLoans } = useContextReports();

  const form = useFormInstance();
  const loans = useWatch('loans', form);
  const movingTo = useWatch(['movingTo', name], form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);

  useEffect(() => {
    if (!movingTo || !loans) return;

    const index = loans.findIndex((loan: IReportLoan) => loan.id === movingTo);

    if (index >= 0 && index !== loanKey) {
      setLoanKey(index);
    }
  }, [movingTo, loans]);

  useEffect(() => {
    if (!movingTo || !isSectionActive || !reportLoans || !loans || !loans[loanKey].zipCode) return;
    const loan = loans?.find((item: IReportLoan) => item.id === movingTo);
    const params: IReportRentalAnalysisParams = {
      id: loan ? loan.id : loans?.[0].id,
    };

    getInitial.fetch(params).then((res) => {
      if (res && res.data) {
        const responseData = JSON.parse(res.data);
        const updatedDatasets = datasets.map((dataset) => {
          if (responseData.includes(dataset.label)) {
            return { ...dataset, isActive: true };
          }

          return { ...dataset, isActive: false };
        });

        setDatasets(updatedDatasets);
      }
    });
    const getDataParams: IReportRentalAnalysisParams = {
      id: loan ? loan.id : loans?.[0].id,
      avg: datasets.some((item) => item.isActive && item.name === 'avg'),
      historyRange: range,
      bedrooms: datasets
        .filter((item) => item.isActive && item.name === 'bedrooms')
        .map((item) => item.value as number),
    };

    rentalAnalysis.fetch(getDataParams).then((res) => {
      if (!res || !Object.keys(res.data).length) return;
      const keyWithArrayValue = Object.entries(res.data)
        .filter(([_, value]) => Array.isArray(value) && value.length > 0)
        .map(([key, _]) => key as IReportRentalAnalysisKey)[0];

      if (!keyWithArrayValue) return;

      const newData: ILineChartData = {
        labels: (res.data[keyWithArrayValue] as { month: string }[]).map((item) =>
          moment(item.month).format('MMM, YYYY')
        ),
        datasets: datasets
          .filter((dataset) => dataset.isActive)
          .map((dataset) => ({
            label: dataset.label,
            data: res.data[
              (dataset.name === 'avg' ? dataset.name : `${dataset.name}${dataset.value}`) as IReportRentalAnalysisKey
            ]?.map((item) => item.averageRent),
            fill: {
              target: 'origin',
              above: `${dataset.color}10`,
            },
            backgroundColor: dataset.color,
            borderColor: dataset.color,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
          })),
      };

      setData(newData);
    });
  }, [movingTo, isSectionActive, loanKey]);

  useEffect(() => {
    if (!movingTo || !isSectionActive || !reportLoans || !loans || !loans[loanKey].zipCode) return;
    const loan = loans?.find((item: IReportLoan) => item.id === movingTo);
    const params: IReportRentalAnalysisParams = {
      id: loan ? loan.id : loans?.[0].id,
      avg: datasets.some((item) => item.isActive && item.name === 'avg'),
      historyRange: range,
      bedrooms: datasets
        .filter((item) => item.isActive && item.name === 'bedrooms')
        .map((item) => item.value as number),
    };

    rentalAnalysis.fetch(params).then((res) => {
      if (!res || !Object.keys(res.data).length) return;

      const keyWithArrayValue = Object.entries(res.data)
        .filter(([_, value]) => Array.isArray(value) && value.length > 0)
        .map(([key, _]) => key as IReportRentalAnalysisKey)[0];

      if (!keyWithArrayValue) return;

      const newData: ILineChartData = {
        labels: (res.data[keyWithArrayValue] as { month: string }[]).map((item) =>
          moment(item.month).format('MMM, YYYY')
        ),
        datasets: datasets
          .filter((dataset) => dataset.isActive)
          .map((dataset) => ({
            label: dataset.label,
            data: res.data[
              (dataset.name === 'avg' ? dataset.name : `${dataset.name}${dataset.value}`) as IReportRentalAnalysisKey
            ]?.map((item) => item.averageRent),
            fill: {
              target: 'origin',
              above: `${dataset.color}10`,
            },
            backgroundColor: dataset.color,
            borderColor: dataset.color,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
          })),
      };

      setData(newData);
    });
  }, [range, datasets, movingTo, isSectionActive]);

  const handleRangeChange = (e: RadioChangeEvent) => {
    setRange(e.target.value);
  };

  const handleRowChange = (id: number, isActive: boolean) => {
    const newDatasets = [...datasets].map((row) => (row.id === id ? { ...row, isActive } : row));

    setDatasets(newDatasets);
  };

  return (
    <ReportSection
      id={name}
      name={name}
      title="Rental Analysis"
      description="This section shows the average
     monthly rent in the zip code of your subject 
    property. Toggle between the different property types to see how rents have 
    changed over time."
      provided={provided}
    >
      <Space style={{ padding: '10px' }} direction="vertical" size={20}>
        <Row>
          <Col xl={16} span={24} key={loanKey}>
            {loans && loans[loanKey]?.zipCode ? (
              <Row gutter={[0, 8]}>
                {datasets[0].isActive &&
                  <Col span={8}>
                    <TextWithLabel
                      label="Average Monthly Rent"
                      value={`$${commafy(rentalAnalysis?.data?.data?.averageRent as unknown as string)}`}
                    />
                  </Col>
                }
                {datasets
                  .filter((element) => element.isActive)
                  .map((element) => {
                    const key = `bedrooms${String(element.value)}Average` as keyof IReportRentalAnalysis;
                    const label = `Average Monthly Rent for ${element.label}`;
                    const value = rentalAnalysis?.data?.data?.[key]?.toString();

                    if (!value) return null;

                    return (
                      <Col key={value} span={8}>
                        <TextWithLabel label={label} value={`$${commafy(value)}`} />
                      </Col>
                    );
                  })}
              </Row>
            ) : null}
          </Col>
          <Col xl={8} span={24}>
            <MovingTo name={name} />
          </Col>
        </Row>

        {loans && loans[loanKey].zipCode ? (
          <LineChart data={data} mode="nearest" loading={!data && rentalAnalysis.loading}>
            <div className="flex-row flex-justify-space-between flex-align-center gap-10">
              <Radio.Group className="flex-1 w-auto" onChange={handleRangeChange} value={range} size="large" />
              <Customize rows={datasets} handleRowChange={handleRowChange} />
            </div>
          </LineChart>
        ) : (
          <div className="flex-col flex-align-center flex-justify-center mh-200">
            <div className="p-block-10 p-inline-10 bg-light-gray br-4">
              <Typography className="color-dark-blue">
                Please enter a property address at the top of the report builder to load this section punctuation period
              </Typography>
            </div>
          </div>
        )}
      </Space>
    </ReportSection>
  );
};

RentalAnalysis.defaultProps = {
  provided: undefined,
};

export default RentalAnalysis;
